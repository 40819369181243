<template>
  <div id="right">
    <div class="backbg one">
      <div class="title">业态信息</div>
      <div class="content1">
        <div
          class="card"
          v-for="(item, index) of numarr"
          :key="index"
          @click="clickact(item.type,index)"
        >
          <div class="img" :class="item.type"></div>
          <div class="num" :class="item.act ? 'act' : ''">
            <span class="text24">{{ item.num }}</span
            >家
          </div>
          <div :class="item.act ? 'act' : ''">{{ item.name }}</div>
          <div class="triangle" v-if="item.act"></div>
        </div>
      </div>
      <div class="content2">
        <div class="s_title">{{ top3arr.name }}Top3</div>
        <div class="card" v-for="(item, index) of top3act.arr" :key="index">
          <div class="flex">
            <div class="top">
              <span>{{ index + 1 }}</span>
            </div>
            <div class="name">
              <div>Top{{ index + 1 }}</div>
              <div>
                <span>{{ item.name }}</span>
              </div>
            </div>
          </div>
          <div class="cost">
            <div>交易额（元）</div>
            <div>
              <span>{{ type_num(item.value) }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="backbg two">
      <div class="title">热门游线</div>
      <div class="list">
        <div
          class="card"
          v-for="(item, index) of hotTouristRouteList"
          :key="index"
          @click="clickhotTour(item.touristRouteId)"
        >
          <img class="img" :src="item.touristRouteImg" alt="">
          <div class="content">
            <div class="name">{{ item.touristRouteName }}</div>
            <div class="info" >{{ match_Reg(item.touristRouteIntroduce)}}</div>
            <div class="cost">
              ￥<span>{{ item.touristRoutePrice }}</span
              >起
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import  {matchReg}  from "@/utils/otherUtils";
export default {
  data() {
    return {
      top3act:{},
    };
  },
  props:{
    top3arr:{
      type:Array
    },
    numarr:{
      type:Array
    },
    hotTouristRouteList:{
      type:Array,
    }
  },
  watch:{
      top3arr(){
         if(this.top3arr.length>0){
           this.top3act=this.top3arr[0]
         }
      }
  },
  methods: {
    match_Reg(str){
      return matchReg(str)
    },
    clickhotTour(id){
        this.$emit('hotTouID',id)
    },
    clickact(type,index) {
      this.numarr.forEach((e,i) => {
        if (type == e.type) {
          this.$set(this.numarr[i],'act',true)
          this.top3act=this.top3arr[index]
        } else {
          this.$set(this.numarr[i],'act',false)
        }
      });

    },
    type_num(num) {
      return Number(num).toFixed(2);
    },
  },
};
</script>

<style lang="scss" scoped>
#right {
  padding: 20px;
  margin-top: 80px;
  width: calc(100% - 40px);
  .one {
    .content1 {
      display: flex;
      margin: 10px 0;
      justify-content: space-around;
      border-bottom: 1px solid #fff;
      .card {
        font-size: 16px;
        font-weight: 400;
        transition: 1s;
        color: #a8d7e8;
        line-height: 22px;
        text-align: center;
        .text24 {
          font-size: 24px;
          font-weight: 600;
        }
        .act {
          color: #fff;
        }
        .num {
          margin-top: 10px;
          margin-bottom: 3px;
        }
        .img {
          width: 65px;
          height: 65px;
         
        }
        .njl {
          background: url("../../../public/static/img/industry/njl.png");
          background-size: 100% 100%;
        }
        .ms {
          background: url("../../../public/static/img/industry/ms.png");
          background-size: 100% 100%;
        }
        .yl {
          background: url("../../../public/static/img/industry/yl.png");
          background-size: 100% 100%;
        }
        .ncp {
          background: url("../../../public/static/img/industry/ncp.png");
          background-size: 100% 100%;
        }
        .xl {
          background: url("../../../public/static/img/industry/xl.png");
          background-size: 100% 100%;
        }
        .triangle {
          transition: 1s;
          width: 0;
          margin: 0 auto;
          margin-top: 15px;
          height: 0;
          border-left: 14px solid transparent;
          border-right: 14px solid transparent;
          border-bottom: 12px solid #fff;
        }
      }
      .act{
        .triangle{
           border-bottom: 12px solid #fff;
        }
      }
    }
    .content2 {
      height: 270px;
      margin: 0 10px;
      color: #fff;

      .s_title {
        font-weight: 600;
        color: #ffffff;
        font-size: 16px;
        line-height: 22px;
      }
      .card {
        height: 60px;
        margin-top: 15px;
        display: flex;
        justify-content: space-between;
        background: linear-gradient(
          180deg,
          rgba(103, 164, 225, 0) 0%,
          rgba(38, 85, 132, 0.3) 100%
        );
        border-radius: 30px;

        padding-right: 10px;
      }
      .flex {
        display: flex;
      }
      .top {
        height: 60px;
        width: 60px;
        background: url("../../../public/static/img/industry/top.png");
        background-size: 100% 100%;
        line-height: 60px;
        text-align: center;
        font-size: 18px;
        font-weight: 600;
      }
      .name,
      .cost {
        margin-top: 10px;
        margin-left: 10px;
        span {
          font-size: 16px;
          font-weight: 600;
          line-height: 22px;
        }
      }
      span {
        background: linear-gradient(180deg, #ffffff 0%, #6af6fa 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }
  }
  .two {
    margin-top: 20px;
    .list {
      padding: 0 22px;
      margin: 15px 0;
      height: 380px;
      overflow: auto;
      .card {
        width: 396px;
        background: linear-gradient(
          180deg,
          rgba(103, 164, 225, 0) 0%,
          rgba(103, 164, 225, 0.3) 100%
        );
        padding: 12px;
        display: flex;
        .img {
          width: 100px;
          height: 100px;
          border: 1px solid #ccc;
        }
        .content {
          display: flex;
          justify-content: space-around;
          flex-direction: column;
          color: #fff;
          margin-left: 15px;
          .name {
            font-size: 20px;
            width: 275px;
            overflow: hidden;
            text-overflow: ellipsis;
            color: #fff;
            white-space: nowrap;
          }
          .info {
            width: 275px;
            overflow: hidden;
            text-overflow: ellipsis;
            height: 21px;
            color: #a8d7e8;
            white-space: nowrap;
          }
          .cost {
            color: #f59a85;
            font-size: 12px;
            span {
              font-size: 16px;
            }
          }
        }
      }
    }
  }
}
</style>
<template>
  <div id="yl">
    <div class="top">
      <img
        class="img"
        :src="industryinfo.logo"
        alt=""
      />
      <div class="info">
        <div>{{industryinfo.name}}</div>
        <div v-if="industryinfo.cost">均价：{{industryinfo.cost}}元</div>
        <div v-if="industryinfo.mincost">最低价：{{industryinfo.mincost}}元</div>
        <div>{{industryinfo.address}}</div>
      </div>
    </div>
    <div class="imggroup">
      <img
        class="img"
        :src="item"
        alt=""
        v-for="item of industryinfo.imgarr"
        :key="item"
      />
    </div>
    <div class="html" v-html="industryinfo.remark"/>
  </div>
</template>
<script>
export default {
  data() {
    return {
        html:" <span>fsdafasfklajfklsajflksdajfdlkdsjklfjahfsdahkjafhkfhdsakj</span>"
    };
  },
  props:{
    industryinfo:{
      type:Object
    },
  },
  mounted(){
    console.log(this.industryinfo)
  }
};
</script>

<style lang="scss" scope>
#yl {
  .top {
    display: flex;
    .img {
      width: 145px;
      height: 110px;
    }
    .info {
      margin-left: 10px;
      display: flex;
      justify-content: space-around;
      flex-direction: column;
      font-size: 12px;
      color: #fff;
    }
    .info > div:nth-child(1) {
      font-size: 16px;
      color: #fff;
      font-weight: 600;
    }
  }
  .imggroup {
    margin-top: 15px;
    display: flex;
    img {
      width: 134px;
      height: 91px;
      margin-right: 7px;
    }
  }
  .html{
    margin-top: 20px;
    color: #FFF !important;
    height: 180px;
    overflow: auto;
  }
}
</style>

<template>
  <div id="left">
    <div class="backbg one">
      <div class="title">经营数据</div>
      <div class="content">
        <div class="data">
          <div class="totalnum">{{type_num(order_sum)}}</div>
          <div class="s_title">总订单数/单</div>
          <div>
            <div class="sum_data" ref="sum_data"></div>
            <div class="num">{{ type_num(order_select) }}</div>
          </div>
          <div class="flex data_title">
            <div class="flex" v-for="(item, index) of orderlist" :key="index">
              <div class="fang" :style="'background:' + item.color"></div>
              <div>{{ item.name }}</div>
            </div>
          </div>
        </div>
        <div class="data">
          <div class="totalnum">{{type_num(cost_sum)}}</div>
          <div class="s_title">总成交金额/元</div>
          <div class="sum_data" ref="sum_money"></div>
          <div class="num">{{type_num(cost_select)}}</div>
          <div class="flex data_title">
            <div class="flex" v-for="(item, index) of costlist" :key="index">
              <div class="fang" :style="'background:' + item.color"></div>
              <div>{{ item.name }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="backbg three">
      <div class="title">发展增收</div>
      <div class="list">
        <div class="card" v-for="(item, index) of developDataList_arr" :key="index">
          <div class="line"></div>
          <div class="s_title">
            {{ item.year }}年份
          </div>
          <div class="content">
            <div>
              <span>村集体经济总收入</span><span class="wtile">{{ type_num(item.villageRevenueMoney) }}万</span>
            </div>
            <div>
              <span>人均可支配收入</span><span class="wtile">{{ type_num(item.personRevenueMoney) }}万/年</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props:{
    orderlist:{
      type:Array
    },
    costlist:{
      type:Array
    },
    developDataList:{
      type:Object
    }
  },
  data() {
    return {
      order_sum:0,
      cost_sum:0,
      order_select:0,
      cost_select:0,
      developDataList_arr:[]
    };
  },
  methods: {
    creatsum_data() {
      var option;
      let mychart = this.$echarts.init(this.$refs.sum_data);
      let one_arr = this.orderlist;
      let that = this;
      option = {
        series: [
          {
            type: "pie",
            radius: ["72%", "90%"],
            avoidLabelOverlap: false,
            label: {
              show: false,
              position: "center",
            },
            emphasis: {
              label: {
                show: false,
                fontSize: "10",
                fontWeight: "bold",
              },
            },
            labelLine: {
              show: false,
            },
            color: [
              one_arr[0].color,
              one_arr[1].color,
              one_arr[2].color,
              one_arr[3].color,
              one_arr[4].color,
            ],
            data: [
              { value: one_arr[0].value, name: one_arr[0].name },
              { value: one_arr[1].value, name: one_arr[1].name },
              { value: one_arr[2].value, name: one_arr[2].name },
              { value: one_arr[3].value, name: one_arr[3].name },
              { value: one_arr[4].value, name: one_arr[4].name },
            ],
          },
        ],
      };
      mychart.setOption(option);
      mychart.dispatchAction({
        type: "highlight", //突出高亮显示;
        seriesIndex: 0,
        dataIndex: 0,
      });
      mychart.on("mouseover", function (e) {
        //downplay;
        that.order_select = e.value;
        for (var i = 0; i < one_arr.length; i++) {
          if (i != e.dataIndex) {
            mychart.dispatchAction({
              type: "downplay", //取消突出高亮显示;
              seriesIndex: 0,
              dataIndex: i,
            });
          }
        }
      });
      //鼠标离开图表时,将鼠标移出的部分突出高亮显示,保持鼠标hover的状态;
      mychart.on("mouseout", function (e) {
       // that.order_select = e.value;
        // e.dataIndex 鼠标移出部分的数据索引;
        mychart.dispatchAction({
          type: "highlight", //突出高亮显示;
          seriesIndex: 0,
          dataIndex: e.dataIndex,
        });
      });
    },
    creatsum_money() {
      var option;
      let mychart = this.$echarts.init(this.$refs.sum_money);
      let one_arr = this.costlist;
      let that = this;
      option = {
        series: [
          {
            type: "pie",
            radius: ["72%", "90%"],
            avoidLabelOverlap: false,
            label: {
              show: false,
              position: "center",
            },
            emphasis: {
              label: {
                show: false,
                fontSize: "10",
                fontWeight: "bold",
              },
            },
            labelLine: {
              show: false,
            },
            color: [
              one_arr[0].color,
              one_arr[1].color,
              one_arr[2].color,
              one_arr[3].color,
              one_arr[4].color,
            ],
            data: [
              { value: one_arr[0].value, name: one_arr[0].name },
              { value: one_arr[1].value, name: one_arr[1].name },
              { value: one_arr[2].value, name: one_arr[2].name },
              { value: one_arr[3].value, name: one_arr[3].name },
              { value: one_arr[4].value, name: one_arr[4].name },
            ],
          },
        ],
      };
      mychart.setOption(option);
      mychart.dispatchAction({
        type: "highlight", //突出高亮显示;
        seriesIndex: 0,
        dataIndex: 0,
      });
      mychart.on("mouseover", function (e) {
        //downplay;
        that.cost_select = e.value;
        for (var i = 0; i < one_arr.length; i++) {
          if (i != e.dataIndex) {
            mychart.dispatchAction({
              type: "downplay", //取消突出高亮显示;
              seriesIndex: 0,
              dataIndex: i,
            });
          }
        }
      });
      //鼠标离开图表时,将鼠标移出的部分突出高亮显示,保持鼠标hover的状态;
      mychart.on("mouseout", function (e) {
        // that.cost_select = e.value;
        mychart.dispatchAction({
          type: "highlight", //突出高亮显示;
          seriesIndex: 0,
          dataIndex: e.dataIndex,
        });
      });
    },
    type_num(num){
       return Number(num).toLocaleString()
    }
  },
  mounted() {
    this.orderlist.forEach(e=>{
      this.order_sum+=Number(e.value)
    })
    this.costlist.forEach(e=>{
      this.cost_sum+=Number(e.value)
    })
    this.order_select=this.orderlist[0].value
    this.cost_select=this.costlist[0].value
    this.creatsum_data();
    this.creatsum_money();
    let developDataList_arr=[]
     for(let i in this.developDataList){
        developDataList_arr.push({
         ...this.developDataList[i]
        })
     }
    this.developDataList_arr=developDataList_arr.reverse()
  } 
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
#left {
  padding: 20px;
  padding-top: 0;
  margin-top: 100px;
  width: calc(100% - 40px);
  .one {
    .content {
      display: flex;
      padding: 30px 0;
      justify-content: space-around;
      .flex {
        display: flex;
      }
      .data {
        width: 220px;
        height: 320px;
        background: url("../../../public/static/img/industry/box.png");
        background-size: 100% 100%;
        position: relative;
        text-align: center;
        .num {
          position: absolute;
          top: 150px;
          color: #fff;
          left: 0px;
          width: 100%;
          text-align: center;
        }
        .data_title {
          margin: 0 auto;
          margin-top: 35px;
          width: 145px;
          flex-flow: wrap;
          justify-content: space-around;
          font-size: 12px;
          color: #fff;
          .fang {
            width: 10px;
            margin-top: 2px;
            margin-right: 5px;
            height: 10px;
            border: 1px solid #fff;
          }
          .flex {
            margin-bottom: 10px;
          }
        }
        .totalnum {
          font-size: 18px;
          font-weight: 600;
          margin-top: 25px;
          color: #ffffff;
          line-height: 25px;
          text-shadow: 0px 0px 1px #00102c;
        }
        .s_title {
          font-weight: 400;
          color: #8dd9ff;
          line-height: 20px;
        }
        .sum_data {
          height: 120px;
          width: 120px;
          margin: 0 auto;
          margin-top: 30px;
        }
      }
    }
  }
  .three {
    margin-top: 30px;

    .list {
      padding: 20px;
       height: 420px;
       overflow:auto;
    }
    .card {
      height: 140px;
      margin-left: 10px;
      position: relative;
      .line {
        position: absolute;
        height: 140px;
        width: 1px;
        border-left: 1px dashed #fff;
      }
      .line::after {
        content: " ";
        left: -6px;
        top:0px;
        display: block;
        border-radius: 50%;
        position: absolute;
        height: 11px;
        width: 11px;
        background: linear-gradient(180deg, #ffffff 0%, #6af6fa 100%);
        border: 1px solid #ffffff;
      }
      .s_title {
        margin-left: 20px;
        font-size: 18px;
        font-weight: 600;
        position: absolute;
        top:-5px;
        line-height: 25px;
        background: linear-gradient(180deg, #ffffff 0%, #6af6fa 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
      }
      .content {
        margin: 0 20px;
        position: absolute;
        top:30px;
        color: #fff;
        width: 360px;
        height: 90px;
        padding: 0 15px;
        line-height: 40px;
        background: linear-gradient(180deg, rgba(103,164,225,0) 0%, rgba(103,164,225,0.45) 100%);
        border-right: 2px solid #66b3cd;
        .wtile {
          color: #fff;
        }
      }
      .content > div {
        display: flex;
        justify-content: space-between;
      }
    }
  }
}
</style>

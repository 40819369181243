<template>
  <div id="bottom">
    <div class="two">
      <div class="head">
         <div class="title"></div>
         <div class="word">营销趋势</div>
      </div>
      <div class="content">
        <div class="all_data" ref="all_data"></div>
        <div class="data_title">
          <div class="flex" v-for="(item, index) of allarr" :key="index">
            <div class="fang" :style="'background:' + item.color"></div>
            <div>{{ item.name }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HelloWorld",
  props: {
    allarr: Array,
    default: [],
  },
  data() {
    return {};
  },
  methods: {
    creatall_data() {
      let that = this;
      let myChart = this.$echarts.init(this.$refs.all_data);
      let series_arr = [];
      this.allarr.forEach((e) => {
        series_arr.push({
          type: "line",
          smooth: 0.6,
          symbol: "none",
          lineStyle: {
            color: `rgba(${e.rgb}, 1)`,
            width: 3,
            backgroundColor: `rgba(${e.rgb}, 0.5)`,
          },
          areaStyle: {
            color: that.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
              {
                offset: 0,
                color: `rgba(${e.rgb}, 0.3)`,
              },
              {
                offset: 1,
                color: `rgba(${e.rgb}, 0.0)`,
              },
            ]),
          },
          data: e.value,
        });
      });
      myChart.setOption({
        color: ["white"],
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
            label: {
              backgroundColor: "#ccc",
            },
          },
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          itemStyle: {
            color: "red",
          },
          axisTick: {
            show: false, //去除刻度线
          },
          axisLine: {
            lineStyle: {
              color: "#ccc",
              width: 1,
            },
          },
          axisLabel: {
            intervalL: 0,
            rotate: 40,
            textStyle: {
              color: "#ccc", //坐标值得具体的颜色
            },
          },
        },
        yAxis: {
          type: "value",
          boundaryGap: [0, "30%"],
          axisLine: {
            lineStyle: {
              color: "#ccc",
              width: 1000,
            },
          },
          textStyle:{
              padding:[0,0,10,0]
          },
          splitLine: {
            show: false,
          },
        },
        visualMap: {
          type: "piecewise",
          show: false,
          dimension: 0,
          seriesIndex: 0,
          pieces: [
            {
              gt: 1,
              lt: 3,
              color: "#ccc",
            },
            {
              gt: 5,
              lt: 7,
              color: "#ccc",
            },
          ],
        },
        series: series_arr,
        grid: {
          left: 80,
          right: 30,
          top: 30,
          bottom: 30,
        },
      });
    },
  },
  mounted() {
    this.creatall_data();
  },
};
</script>
<style lang="scss" scoped>
#bottom {
  .two {
    margin-top: 20px;
    height: 280px;
    background: linear-gradient(
      270deg,
      rgba(10, 36, 48, 0.79) 0%,
      rgba(15, 39, 70, 0.26) 100%
    );
    .content {
      display: flex;
      justify-content: space-around;
    }
    .title{
       background: url("../../../public/static/img/healthy-head.png");
        background-size: 100% 100%;
        width: 423px;
        height: 39px;
        opacity: 0.7;
        position: absolute;
        top: 0px;
    }
    .head{
      position: relative;
        margin-top: 10px;
        margin-left: 10px;
        width: 423px;
        line-height:30px ;
        height: 39px;
        color: #fff;
        padding-left:10px;
    }
    .word{
      position: absolute;
      top:5px;
      left: 30px;
    }
    .flex {
      display: flex;
    }
    .data_title {
      margin-top: 30px;
      width: 80px;
      display: flex;
      flex-flow: wrap;
      justify-content: space-around;
      font-size: 12px;
      color: #fff;
      .fang {
        width: 20px;
        margin-top: 2px;
        margin-right: 10px;
        height: 10px;
        border: 1px solid #fff;
      }
      .flex {
        margin-bottom: 10px;
      }
    }
    .all_data {
      padding: 0 15px;
      height: 200px;
      padding: 20px;
      width: 760px;
    }
  }
}
</style>

<template>
  <div class="lx">
    <div class="title">研学一日游</div>
    <div class="topbar">
      <div :class="act == true ? 'act' : ''" @click="act = true">详情</div>
      <div :class="act == false ? 'act' : ''" @click="act = false">规划</div>
    </div>
    <div class="detail" v-if="act" v-html="detail.introduce"></div>
    <div class="guihua" v-if="!act">
      <div class="pmb-day">
        <div
          v-for="(item, index) in days"
          :key="index"
          :class="dayType == index ? 'active' : ''"
          @click="changeDay(index)"
        >
          Day{{ index + 1 }}
        </div>
      </div>
      <div>
        <div class="process">
          <div class="wrap" v-for="(i, index) in planList" :key="index">
            <div class="desc" :class="index == 0 ? 'active' : ''">
              <div class="icon">
                {{ timeZoneCheck(i.zone) }}
              </div>
              <span class="title">{{ i.projectName }}</span>
            </div>
            <div class="text">
              {{ i.descripte }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  industrtraveldetail,
  industrtravelday,
  industrtravellist,
} from "@/api/permission";
export default {
  data() {
    return {
      act: true,
      days: 1,
      dayType: 0,
      planList: [],
      detail: {},
    };
  },
  props:{
    xlid:{
      type:String
    },
  },
  methods: {
    timeZoneCheck(type) {
      switch (type) {
        case 0:
          return "上午";
        case 1:
          return "中午";
        case 2:
          return "下午";
        case 3:
          return "晚上";
      }
    },
    changeDay(index) {
      this.dayType = index;
      this.todayList();
    },
    todayList() {
      industrtravellist({ routeId: this.xlid, today: this.dayType + 1 }).then(
        (res) => {
          this.planList = res;
          console.log(this.planList);
        }
      );
    },
    routeDays() {
      industrtravelday({ routeId: this.xlid }).then((res) => {
        this.days = res.days;
        this.todayList();
      });
    },
    getdetail() {
      industrtraveldetail({ routeId: this.xlid }).then((res) => {
        this.detail = {
          introduce: res.introduce,
        };
      });
    },
  },
  mounted() {
    this.getdetail();
    this.routeDays();
  },
};
</script>

<style lang="scss" scope>
.lx {
  .title {
    font-size: 18px;
    font-weight: bold;
    color: #ffffff;
    line-height: 30px;
  }
  .topbar {
    display: flex;
    justify-content: space-between;
    width: 100px;
    margin-top: 5px;
    color: #ccc;
    cursor: pointer;
    .act {
      color: #fff;
    }
  }
  .detail {
    margin-top: 20px;
    color: #fff;
    height: 330px;
    overflow: auto;
  }
  /* 时间线 */
  .pmb-day {
    margin-top: 10px;
    color: #fff;
    display: flex;
  }
  .pmb-day>div{
    margin-right:10px;
    color: #ccc;
    cursor: pointer;
  }
  .pmb-day .active{
    color: #fff;
  }
  .process p {
    padding: 0;
    margin: 0;
  }
  .process {
    margin-top: 25px;
    position: relative;
    padding-left: 24px;
    height: 300px;
    overflow: auto;
  }
  .process .wrap {
    padding-bottom: 32px;
    padding-left: 45px;
    border-left: 1px dashed #979797;
  }
  .process .wrap:last-child {
    border: none;
  }
  .process .title {
    font-size: 16px;
    color: #fff;
    font-weight: 800;
    display: inline-block;
  }
  .process .text {
    font-size: 14px;
    color: #fff;
    text-indent: 2em;
    padding-right: 30px;
  }
  .process .desc {
    position: relative;
  }
  .process .icon {
    width: 33px;
    height: 33px;
    border-radius: 50%;
    position: absolute;
    left: -64px;
    top: 0;
    background: rgb(10, 42, 64);
    font-style: normal;
    text-align: center;
    line-height: 33px;
    font-size: 14px;
    border: 1px solid #fff;
    color: #fff;
  }
  .process .imgs {
    display: flex;
    margin-top: 16px;
    overflow-x: auto;
    flex-wrap: nowrap;
  }
  .process .imgs .bg-img {
    width: 290px;
    height: 216px;
    border-radius: 12px;
    margin-right: 16px;
  }
}
</style>
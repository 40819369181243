<template>
  <div class="traffic">
    <div class="left">
      <left :orderlist="orderlist" :costlist="costlist" :developDataList="developDataList" v-if="orderlist.length && costlist.length"></left>
    </div>
    <div class="right">
      <right :top3arr="top3arr" :numarr="numarr" :hotTouristRouteList="hotTouristRouteList" @hotTouID="clickhotTouID"    ></right>
    </div>
    <div class="topcard">
      <div>
        <card :num="passengerFlow.monthNum||0" :name="'客流（人）'" :angin="'left'" />
        <img src="../../../public/static/img/industry/yue.png" alt="" />
      </div>
      <div>
        <card :num="passengerFlow.weekNum||0" :name="'客流（人）'" :angin="'left'" />
        <img src="../../../public/static/img/industry/zhou.png" alt="" />
      </div>
      <div>
        <card :num="passengerFlow.dayNum||0" :name="'客流（人）'" :angin="'left'" />
        <img src="../../../public/static/img/industry/ri.png" alt="" />
      </div>
    </div>
    <div class="conent" v-if="industryoff">
      <div class="top">
        <div class="title">{{ ytname }}详情</div>
        <div class="pdclose" @click="clickclose"></div>
      </div>
      <div class="detail">
        <yl v-if="ytname != '线路'" :industryinfo="industryinfo"/>
        <!-- 娱乐 农家乐 农产 民宿一样 -->
        <xl v-if="ytname == '线路'" :xlid="xlid"/>
      </div>
    </div>
    <div class="bottom" v-if="allarr.length > 0">
      <bottom :allarr="allarr" />
    </div>
  </div>
</template>
<script>
import { industrybusiness1, industrybusiness2,industrynjl,industryyl,industryms,industryncp,industrygerFlow } from "@/api/permission";
import card from "@/components/card.vue";
import left from "./left.vue";
import right from "./right.vue";
import yl from "./yl.vue";
import xl from "./xl.vue";
import bottom from "./bottom.vue";
export default {
  name: "traffic",
  components: {
    left,
    right,
    yl,
    xl,
    card,
    bottom,
  },
  watch: {
    EntityData(newval) {
      this.getmapdata(newval.type,newval.businessEntityId)
    }
  },
  computed: {
    EntityData() {
      return this.$store.state.EntityData
    },
    villageId(){
      return this.$store.state.villageId;
    }
  },
  data() {
    return {
      ytname: "线路",
      top3arr: [], //right top3数据
      allarr: [], //bottom底部全部数据
      numarr: [], //right业态数量
      orderlist: [], //left业态订单总数
      costlist:[],//left 总成交金额
      developDataList:[],//年份收入
      hotTouristRouteList:[], //热门线路
      industryinfo:{}, //其他详情内容
      xlid:"",   //线路ID
      industryoff:false,  //详情弹框
      passengerFlow:{}, //客流数据
    };
  },
  methods: {
    getdata() {
      let that = this;
      industrybusiness1({ villageId: this.villageId }).then((res) => {
        this.top3arr = [
          { type: "njl", name: "农家乐", arr: res.farmhouseTopList || [] },
          { type: "ms", name: "民宿", arr: res.homestayTopList || [] },
          { type: "yl", name: "娱乐", arr: res.entertainmentTopList || [] },
          { type: "ncp", name: "农场", arr: res.shopTopList || [] },
          { type: "xl", name: "游线", arr: res.touristRouteTopList || [] },
        ];
        this.allarr = [
          {
            value: that.type_mouth(res.shopMonthlyVolumeList),
            name: "农产品",
            color: "#6692FF",
            rgb: "102,146,255",
          },
          {
            value: that.type_mouth(res.farmhouseMonthlyVolumeList),
            name: "农家乐",
            color: "#65EAA2",
            rgb: "101,234,162",
          },
          {
            value: that.type_mouth(res.homestayMonthlyVolumeList),
            name: "民宿",
            color: "#FEE186",
            rgb: "254,225,134",
          },
          {
            value: that.type_mouth(res.entertainmentMonthlyVolumeList),
            name: "娱乐",
            color: "#C698ED",
            rgb: "198,152,237",
          },
          {
            value: that.type_mouth(res.touristRouteMonthlyVolumeList),
            name: "游线",
            color: "#67EAFF",
            rgb: "103,234,255",
          },
        ];
      });
    },
    getdata2() {
      industrybusiness2({ villageId: this.villageId }).then((res) => {
        this.numarr = [
          { type: "njl", name: "农家乐", num: res.farmhouseNum, act: true },
          { type: "ms", name: "民宿", num: res.homestayNum, act: false },
          { type: "yl", name: "娱乐", num: res.entertainmentNum, act: false },
          { type: "ncp", name: "农产品", num: res.shopNum, act: false },
          { type: "xl", name: "游线", num: res.touristRouteNum, act: false },
        ];
        this.orderlist = [
          { value: res.shopOrderNum, name: "农产品", color: "#FFFFFF" },
          { value: res.farmhouseOrderNum, name: "农家乐", color: "#FEE186" },
          { value: res.homestayOrderNum, name: "民宿", color: "#89F3F7" },
          { value: res.entertainmentOrderNum, name: "娱乐", color: "#628EF6 " },
          { value: res.touristRouteOrderNum, name: "游线", color: "#FE9D86" },
        ];
        this.costlist=[
          { value: res.shopVolume, name: "农产品", color: "#FFFFFF" },
          { value: res.farmhouseVolume, name: "农家乐", color: "#FEE186" },
          { value: res.homestayVolume, name: "民宿", color: "#89F3F7" },
          { value: res.entertainmentVolume, name: "娱乐", color: "#628EF6 " },
          { value: res.touristRouteVolume, name: "游线", color: "#FE9D86" },
        ]
        this.developDataList=res.developDataList
        this.hotTouristRouteList=res.hotTouristRouteList
      });
    },
    type_mouth(arr) {
      let newarr = [];
      if (!arr) return [];
      arr.forEach((e) => {
        newarr.push([e.name, e.value]);
      });
      return newarr;
    },
    clickclose(){
      this.industryoff=false
    },
    clickhotTouID(id){ //点击线路
         this.xlid=id
        this.ytname='线路';
        this.industryoff=true
    },
    async getmapdata(type,id){
      console.log(type)
       if(type==2){
         this.ytname='农家乐'
         await this.getnjl(id)
       }else if(type==6){
        this.ytname='娱乐'
         await this.getyl(id)
       }else if(type==1){
          this.ytname='民宿'
          await this.getms(id)
       }else if(type==8){
          this.ytname='农产品'
          await this.getncp(id)
       }
    },
    computcost(arr){
      if(arr.length==0) return "0.00"
        let sum=0;
      arr.forEach(e=>{
        sum+=Number(e.price)
      })
      return (sum/arr.length).toFixed(2)
    },
    type_imgmax(richtext){
      const regex = new RegExp("<img", "gi");
      return richtext.replace(regex, `<img style="max-width: 100%;"`);
    },
    getnjl(id){
       industrynjl({storeId:id}).then(res=>{
          this.industryinfo={
             name:res.name,
             address:res.address,
             cost:res.consumptionLevel,
             imgarr:res.carousel,
             remark:this.type_imgmax(res.remark),
             logo:res.logoUrl
          }
          this.industryoff=true
       })
    },
    getyl(id){
       industryyl({entertainmentId:id}).then(res=>{
          this.industryinfo={
             name:res.name,
             address:res.address,
             cost:this.computcost(res.setMealList),
             imgarr:res.featureIntroductionUrls,
             remark:this.type_imgmax(res.featureIntroduction),
             logo:res.logoUrl
          }
          this.industryoff=true
       })
    },
    getms(id){
       industryms({storeId:id}).then(res=>{
          this.industryinfo={
             name:res.name,
             address:res.address,
             mincost:(res.price/100).toFixed(2),
             imgarr:JSON.parse(res.imgs) ,
             remark:this.type_imgmax(res.description),
             logo:res.logo
          }
          this.industryoff=true
       })
    },
    getncp(id){
       industryncp({storeId:id}).then(res=>{
          this.industryinfo={
             name:res.storeName,
             address:res.address,
             mincost:res.minPrice|| '0.00',
             imgarr:res.imgs,
             remark:this.type_imgmax(res.introduce),
             logo:res.storeLogo
          }
          this.industryoff=true
       })
    },
    getgerFlow(){ //客流
      industrygerFlow().then(res=>{
         this.passengerFlow={...res}
         //console.log(this.passengerFlow)
      })
    }
  },
  mounted() {
    this.getdata();
    this.getdata2();
    this.getgerFlow()
    this.$store.commit("SET_showtype", "1,2,6,8,1668754199819");
  },
};
</script>
<style scoped lang="scss">
.traffic {
  .left,
  .right,
  center,
  .left,
  .right {
    position: fixed;
    width: 26%;
    height: calc(100vh);
  }
  .left {
    left: 0;
    background: linear-gradient(
      270deg,
      rgba(15, 39, 70, 0.2) 0%,
      rgba(10, 36, 48, 0.79) 100%
    );
  }
  .right {
    right: 0;
    background: linear-gradient(
      270deg,
      rgba(10, 36, 48, 0.79) 0%,
      rgba(15, 39, 70, 0.2) 100%
    );
  }
  .topcard {
    left: 27%;
    position: absolute;
    display: flex;
    width: 900px;
    justify-content: space-around;
    top: 100px;
  }
  .conent {
    left: 27%;
    position: absolute;
    top: 250px;
    width: 707px;
    background: rgba(10, 42, 64, 0.49);
    border: 1px solid #064f89;
    .top {
      height: 40px;
      display: flex;
      justify-content: space-between;
      width: 100%;
      background: rgba(62, 106, 161, 0.6);
      .title {
        margin-left: 10px;
        font-size: 18px;
        color: #ffffff;
        font-weight: 600;
        line-height: 38px;
        background: linear-gradient(180deg, #ffffff 0%, #6af6fa 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
      .pdclose {
        width: 18px;
        height: 18px;
        cursor: pointer;
        float: right;
        position: relative;
        overflow: hidden;
        margin-top: 10px;
        opacity: 0.5;
        margin-right: 10px;
      }
    }
    .detail {
      padding: 15px;
    }
  }
  .topcard > div {
    position: relative;
    width: 277px;
    img {
      width: 28px;
      height: 24px;
      position: absolute;
      right: 5px;
      top: 5px;
    }
  }
  .bottom {
    position: fixed;
    bottom: 0;
    left: 26%;
    width: 47.9%;
    margin: 0 auto;
  }
}
</style>
<template>
  <div>
    <div class="card" :style="`width:${width};`">
      <div class="icon">
        <img src="../../public/static/img/djck-r.png" alt="">
      </div>
      <div class="content" :style="`text-align:${angin};`">
        <div>{{ type_num(num)  }}</div>
        <div>{{ name }}</div>
      </div>
      <div class="top_l jiao"></div>
      <div class="top_r jiao"></div>
      <div class="bottom_l jiao"></div>
      <div class="bottom_r jiao"></div>
    </div>
  </div>
</template>
<script>
export default {
  name: "card",
  props: {
    name: {
      type: String,
    },
    num: {
      type: [String,Number],
    },
    width:{
      type:String,
      default:'277px'
    },
    angin:{
      type:String,
      default:'center'
    }
  },
  data() {
    return {};
  },
  methods: {
    type_num(num){
       return Number(num).toLocaleString()
    }
  },
};
</script>
<style scoped lang="scss">
.card {
  margin-right: 20px;
  background-color: rgba(10, 36, 48, 0.7);
  position: relative;
  height: 100px;
  box-shadow: inset 0px 0px 1px 0px rgba(147, 196, 226, 0.3);
  border: 1px solid rgba(157, 163, 175, 0.5);
  display: flex;
  .icon {
    width: 94px;
    height: 98px;
    // opacity: 0.66;
    border: 1px solid;
    border-image: linear-gradient(134deg, rgba(254, 255, 255, 1), rgba(254, 255, 255, 0)) 1 1;
    background: url("../../public/static/img/djck.png");
    display: flex;
    justify-content: center;
    align-items: center;
    background-size: 100% 100%;
    img{
      margin: 0 auto;
      width: 50px;
      height: 50px;
    }
  }
  .jiao{
    width: 4.5px;
    height: 4.5px;
    position: absolute;
    background: #6CF6FA;
  }
  .top_l{
    top:0;
    left:0;
  }
  .top_r{
    top:0;
    right:0;
  }
  .bottom_l{
    bottom: 0;
    left:0;
  }
  .bottom_r{
    bottom: 0;
    right:0;
  }
  .content {
    width: 150px;
    height: 70px;
    padding: 15px;
  }
  .content div:nth-child(1) {
    width: 158px;
    height: 38px;
    font-size: 27px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #ffffff;
    line-height: 38px;
    background: linear-gradient(180deg, #ffffff 0%, #6af6fa 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  .content div:nth-child(2) {
    width: 158px;
    height: 25px;
    font-size: 18px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #a8d7e8;
    line-height: 25px;
  }
}
</style>